export default {
  accompanist: {
    title: 'Accompagnateurs',
    titleAccompanistsEmployees: 'Salariés Accompagnateurs',
    titleAccompanistsCompanies: 'Entreprises Accompagnateurs',
    titleAccompanistsAgencies: 'Agences Accompagnateurs',
    titleAccompanistOperationalArea: "Zone d'intervention de ",
    BY: 'par',
    companyState: "État de l'entreprise :",
    agencyState: "État de l'agence :",
    accompanistCompanyState: {
      PROPOSED: 'Proposée',
      PARTNER: 'Partenaire'
    },
    popinSendInviationAccompanist: {
      title: 'Souhaitez-vous envoyer une invitation à créer un compte Espace MAR ?',
      sentenceOne:
        'L’invitation à créer le compte sera envoyée sur l’email du salarié et sera valable 2 jours.',
      sentenceTwo:
        'Une mention “Compte espace MAR actif” apparaîtra ici sur le salarié lorsqu’il aura créé son compte.',
      send: 'Oui, envoyer',
      successSend: 'Invitation espace MAR bien envoyée',
      sendMail: 'Envoyer une invitation',
      cancel: 'Non, annuler'
    },
    accompanistAgencyState: {
      PROPOSED: 'Proposée',
      PARTNER: 'Partenaire',
      DIRECTORY: 'Annuaire',
      FRANCE_RENOV: "France Rénov'"
    },
    accompanistCompanyStateLabel: 'État de la société Accompagnatrice',
    accompanistAgencyStateLabel: "État de l'agence Accompagnatrice",
    legend: {
      title: 'Format à respecter',
      lengthRule: 'Les codes INSEE doivent être des nombres à 5 chiffres.',
      exampleRule:
        "Vous pouvez en renseigner plusieurs à la fois en les séparant d'un retour à la ligne, d'une virgule ou d'un point virgule.",
      exampleCodeINSEE: 'Par exemple:\n29212\n35000'
    },
    table: {
      title: 'Liste des accompagnateurs',
      company: {
        title: 'Liste des entreprises accompagnateurs'
      },
      agency: {
        title: 'Liste des agences accompagnateurs'
      },
      columns: {
        lastname: 'Nom',
        firstname: 'Prénom',
        accompanistCompany: 'Entreprise Accompagnatrice',
        company: 'Entreprise',
        phoneNumber: 'Numéro de téléphone',
        state: 'Etat'
      },
      deactivate: {
        see: 'Voir les agences désactivées',
        hide: 'Cacher les agences désactivées',
        label: 'Désactivé',
        action: 'Désactiver',
        revert: 'Réactiver',
        popin: {
          title: "Désactiver définitivement une agence Accompagnateur Rénov'",
          accept: 'Oui, Désactiver définitivement',
          cancel: 'Non, annuler',
          questionConfirm:
            "Êtes-vous sûr de vouloir désactiver l'agence Accompagnateur Rénov' {businessName} définitivement ?",
          descriptionAction:
            "Vous ne pourrez plus accéder à cette agence Accompagnateur Rénov' sans la réactiver."
        }
      }
    },
    creation: {
      company: {
        title: "Création d'une entreprise accompagnateur"
      },
      agency: {
        title: "Création d'une agence accompagnateur"
      },
      title: "Création d'un salarié accompagnateur"
    },
    update: {
      company: {
        title: "Détails de l'entreprise accompagnateur"
      },
      agency: {
        title: "Détails de l'agence accompagnateur"
      },
      title: 'Détail du salarié accompagnateur'
    },
    errors: {
      create: {
        CONFLICT: 'Une personne existe déjà avec ces informations'
      },
      update: {
        CONFLICT: 'Une personne existe déjà avec ces informations'
      },
      account: {
        create:
          "Une erreur est survenu lors de la création du compte utilisateur pour l'accompagnateur"
      }
    },
    opah: {
      title: 'Liste des OPAH',
      placeholder: 'Sélectionner des OPAH...',
      noResultFound: 'Aucun résultat trouvé'
    },
    company: {
      delete: {
        title: "Supprimer définitivement une entreprise Accompagnateur Rénov'"
      }
    },
    agency: {
      delete: {
        title: "Supprimer définitivement une agence Accompagnateur Rénov'"
      }
    },
    accountStateLegend: {
      title: "Etat d'un compte",
      noInvitation: {
        title: 'Logo cliquable invitation : ',
        description: '{title} le salarié n’a pas encore été invité à créer un espace MAR.'
      },
      invitationSent: {
        title: 'Invitation envoyée : ',
        description:
          '{title} le salarié à reçu une invitation à créer un espace MAR mais n’a pas encore créé le compte.'
      },
      activedAccount: {
        title: 'Compte espace MAR actif : ',
        description: '{title} le salarié à créé un compte espace MAR et l’utilise.'
      },
      deactivatedAccount: {
        title: 'Compte espace MAR désactivé : ',
        description: '{title} le salarié à créé un compte espace MAR mais ne l’utilise plus.'
      }
    },
    userAccountState: {
      NO_ACCOUNT: 'Compte non créé',
      INVITATION_SENT: 'Invitation envoyée',
      ACTIVATED: 'Actif',
      DEACTIVATED: 'Désactivé'
    }
  },

  operationalArea: {
    exportCSV: 'Exporter le fichier des codes Insee',
    exportCSVFileName: 'codesInsee_{fileName}.csv',
    replaceAll: 'Remplacer tout',
    replaceAllPopin: {
      title: 'Remplacer tous les codes INSEE',
      accept: 'Oui, remplacer',
      cancel: 'Non, annuler',
      questionConfirm:
        'Souhaitez-vous vraiment remplacer tous les codes INSEE par votre liste ? Cette action est irréversible.'
    },
    columns: {
      codesINSEE: 'Code INSEE'
    },
    arrayName: 'Liste des codes INSEE',
    toasts: {
      updated: {
        title: 'Modification effectuée',
        content: "La zone d'intervention a bien été mise à jour."
      },
      deleted: {
        title: 'Suppression effectuée',
        content: "le code INSEE {code} ne fait plus partie de la zone d'intervention"
      },
      error: {
        incorrectLength: 'Un code INSEE doit être un nombre à 5 chiffres',
        multipleIncorrectCodes:
          'Les codes INSEE suivants ne sont pas corrects: {errorListCodeInsee}'
      }
    },
    delete: {
      title: 'Supprimer définitivement un code INSEE',
      accept: 'Oui, supprimer définitivement',
      cancel: 'Non, annuler',
      questionConfirm:
        'Êtes-vous sûr de vouloir supprimer le code INSEE {codeINSEE} de {businessName} définitivement ?',
      descriptionAction: "L'accompagnateur Rénov' ne sera plus remonté pour ce code INSEE."
    },
    confirm: {
      title: "Êtes vous sûr de vouloir retourner sur la liste des sociétés d'accompagnateur ?",
      content: 'Si vous continuez, vous allez perdre votre saisie.'
    }
  }
}
