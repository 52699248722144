export default {
  featureFlipping: {
    title: 'Gestion du feature-flipping',
    table: {
      columns: {
        name: 'Nom du tag',
        state: 'Etat',
        startingDateApplication: "Début d'application",
        endingDateApplication: "Fin d'application"
      },
      title: 'Liste des feature-flipping tags'
    },
    state: {
      ENABLED: 'Activé',
      DISABLED: 'Désactivé',
      CUSTOM: 'Personnalisé'
    },
    toasts: {
      creation: {
        summary: 'Votre tag "{tagName}" a bien été créé'
      },
      modification: {
        summary: 'Le tag "{tagName}" a bien été modifié'
      },
      suppression: {
        summary: 'Le tag "{tagName}" a bien été supprimé'
      }
    },
    editDialog: {
      titleCreation: "Création d'un tag feature flipping",
      titleModification: "Modification d'un tag feature flipping",
      add: 'Ajouter',
      cancel: 'Annuler',
      save: 'Enregistrer',

      fields: {
        name: 'Nom du tag',
        description: 'Description du tag',
        startingDate: "Date de début d'application",
        endingDate: "Date de fin d'application"
      },
      placeholder: {
        state: 'Etat du tag'
      },
      label: {
        state: 'Etat du flag',
        allowedEmail: 'Email ou domaine autorisé',
        allowedEmailExplication:
          "Pour autoriser un domaine entier veuillez saisir '*' pour la partie gauche de l'email (ex: *{'@'}nidomio.fr)"
      },
      form: {
        errors: {
          tagName: 'Merci de respecter la syntaxe snake case en majuscule (ex: MON_TAG)',
          invalidEmailOrDomain: 'Merci de saisir une adresse email ou un domaine valide',
          hasAlreadyEmailOrDomain: "L'adresse email ou le domaine a déjà été saisi."
        }
      }
    },
    confirm: {
      title: "Êtes vous sûr de vouloir supprimer le tag '{tagName}' ?"
    }
  }
}
