export default {
  user: {
    civilityTitle: {
      mr: 'Monsieur',
      mrs: 'Madame'
    },
    personalInformation: {
      title: 'Mes informations personnelles',
      passwordReset: {
        title: 'Réinitialiser mon mot de passe',
        validationCriterias: {
          rules: 'Le mot de passe doit contenir :',
          uppercase: 'majuscule',
          lowercase: 'minuscule',
          digit: 'chiffre',
          specialCharacter: 'caractère spécial',
          sizeMin: 'caractères minimum'
        },
        messages: {
          passwordResetSuccess: 'Votre nouveau mot de passe a bien été enregistré.'
        },
        errors: {
          required:
            'Le mot de passe est essentielle pour la sécurité de votre espace personnel. Merci de saisir un mot respectant les règles de sûreté.',
          moreSecurity:
            "Votre mot de passe mérite plus de sécurité. Nous vous conseillons d'ajouter les caractères manquants signalés ci-dessus pour renforcer l'accès à votre espace personnel."
        }
      }
    },
    messageSuccessMailResetPassword:
      'Un courriel contenant un lien pour réinitialiser votre mot de passe vient de vous être envoyé.'
  }
}
