<script setup lang="ts">
import ProgressBar from 'primevue/progressbar'
import TheLogo from '@/common/components/icons/TheLogo.vue'
import SideBarMenu from './SideBarMenu.vue'
import NavigationMenu from '../navigation/NavigationMenu.vue'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { useTrackDataAPIStore } from '@/common/stores/trackDataApi'

const route = useRoute()
const withoutMenu = computed(() => route.meta.landingPage)

const trackDataAPIStore = useTrackDataAPIStore()
const hasLoading = computed(() => trackDataAPIStore.hasLoading())
</script>
<template>
  <header class="justify-content-between pl-3 pr-3 lg:pl-6 lg:pr-6">
    <router-link :to="{ name: 'dashboard' }" custom v-slot="{ navigate }">
      <div
        class="flex flex-row align-items-center cursor-pointer"
        :aria-label="$t('common.header.client.labelLogo')"
        @click="navigate"
        @keydown.enter="navigate()"
        tabindex="0"
      >
        <TheLogo data-testid="logo" />
        <span class="w-5rem text-600 font-medium">Espace <b>Admin</b></span>
        <span class="visually-hidden" v-t="'common.header.client.labelLogo'" />
      </div>
    </router-link>
    <SideBarMenu class="lg:hidden" v-if="!withoutMenu">
      <template v-slot:navigation-menu>
        <NavigationMenu :is-mobile="true" />
      </template>
    </SideBarMenu>
  </header>
  <ProgressBar v-if="hasLoading" mode="indeterminate" class="header-loader"></ProgressBar>
</template>
<style scoped>
.logo {
  height: 4.063rem;
}
.header-loader {
  z-index: 998;
  margin-top: 4.125rem;
  height: 3px;
  width: 100%;
  position: fixed;
  border-radius: 0;
}
</style>
