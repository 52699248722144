<script setup lang="ts">
import { ref } from 'vue'
import Sidebar from 'primevue/sidebar'
import Button from 'primevue/button'
import { useRouter } from 'vue-router'
import TheLogoWhite from '@/common/components/icons/TheLogoWhite.vue'

const visible = ref(false)

const router = useRouter()

const beforeRouteLeave = () => {
  visible.value = false
}

router.beforeEach(beforeRouteLeave)
</script>

<template>
  <div class="card flex justify-content-center">
    <Sidebar v-model:visible="visible" class="w-full" :showCloseIcon="false">
      <slot name="navigation-menu"></slot>
      <template #header>
        <div class="flex flex-row align-items-center cursor-pointer">
          <TheLogoWhite data-testid="logo-app" />
          <span class="w-5rem text-0 font-medium">Espace <b>Admin</b></span>
          <span class="visually-hidden" v-t="'common.header.client.labelLogo'" />
        </div>
        <Button
          class="close-button border-white"
          iconPos="right"
          @click="visible = false"
          rounded
          outlined
        >
          <label class="close-label">{{ $t('common.sideBarMenu.header.closeLabel') }}</label>
          <i class="x-icon ti ti-x text-white"></i>
        </Button>
      </template>
    </Sidebar>
    <Button class="menu-button" iconPos="right" @click="visible = true" rounded outlined>
      <label class="menu-label">{{ $t('common.header.menuLabel') }}</label>
      <i class="ti ti-menu-2 icon-menu"></i>
    </Button>
  </div>
</template>

<style scoped>
label {
  padding-right: 0.5rem;
  font-weight: 700;
  cursor: inherit;
}

.close-button,
.menu-button {
  margin: 0.813rem 0;
}

.close-label {
  color: var(--text-color-side-bar-menu) !important;
}

.menu-label {
  color: var(--text-color-burger-header) !important;
}

.icon-menu {
  color: var(--text-color-burger-header);
}

.x-icon {
  font-size: 1.2rem;
}
</style>
