import { http } from '@/common/api'
import type { PersonalInformations } from '@/common/models/personalInformations'
import type { ValidateAccompinistInvitationResponse } from '../models/validateAccompinistInvitationResponse'
import type { ValidateTokenCreation } from '../models/validateTokenCreation'

export const login = (email: string, password: string, trackId = 'oauth-login'): Promise<void> => {
  const body = `email=${email}&password=${password}`
  return http
    .post('/oauth/login', body, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      trackId
    })
    .then((response) => response.data)
}

export const refreshToken = (trackId = 'refresh-token'): Promise<void> => {
  return http
    .post('/oauth/refresh-token', {
      trackId
    })
    .then((response) => response.data)
}

export async function checkTokenValidity(
  uuid: string,
  trackId = 'check-token-validity'
): Promise<ValidateTokenCreation> {
  return http
    .post('/access-contexts/validate', { uuid }, { trackId })
    .then((response) => response.data)
}

export const usersInfos = (trackId = 'get-me'): Promise<PersonalInformations> => {
  return http.get('/users/me', { trackId }).then((response) => response.data)
}

export const updatePersonalInformations = (
  personalInformations: PersonalInformations,
  trackId = 'update-personal-informations'
): Promise<void> =>
  http.put('/users/me', { ...personalInformations }, { trackId }).then((response) => response.data)

export async function createPassword(
  password: string,
  personId: number,
  trackId = 'create-password'
) {
  const body = `password=${password}&personId=${personId}`

  return http
    .post('/users/password', body, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      trackId
    })
    .then((response) => response.data)
}

export const magicLink = (email: string, trackId = 'magic-link-generation') => {
  const uuid = new URLSearchParams(window.location.search).get('uuid')
  const redirectUri = new URL(window.location.origin + '/auth/magic-link-signin')
  if (uuid) {
    redirectUri.searchParams.append('uuid', uuid)
  }

  return http
    .post(
      '/oauth/magic-link',
      { email, redirectUri },
      {
        trackId
      }
    )
    .then((response) => {
      return response.data
    })
}

export const magicLinkSignIn = (
  code: string | null,
  trackId = 'magic-link-signin'
): Promise<void> => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const routeName = urlSearchParams.get('redirectRouteName')
  const routeParams = urlSearchParams.get('routeParams')
  const uuid = urlSearchParams.get('uuid')
  const projectId = urlSearchParams.get('projectId')

  const redirectUri = new URL(window.location.origin + '/auth/magic-link-signin')
  if (routeName) {
    redirectUri.searchParams.append('redirectRouteName', routeName)
  }
  if (routeParams) {
    redirectUri.searchParams.append('routeParams', routeParams)
  }
  if (uuid) {
    redirectUri.searchParams.append('uuid', uuid)
  }
  if (projectId) {
    redirectUri.searchParams.append('projectId', projectId)
  }

  return http
    .post('/oauth/magic-link-validation', { code, redirectUri }, { trackId })
    .then((response) => {
      return response.data
    })
}

export const validateAccompinistInvitation = (
  uuid: string,
  trackId = 'validate-accompanist-invitation'
): Promise<ValidateAccompinistInvitationResponse> => {
  return http
    .post('/oauth/validate-accompanist-invitation', { id: uuid }, { trackId })
    .then((response) => response.data)
}

export const revokeToken = (trackId = 'revoke-token'): Promise<void> => {
  return http.post('/oauth/revoke', { trackId }).then((response) => {
    return response.data
  })
}
