<template>
  <TheEnvBanner></TheEnvBanner>
  <Toast position="bottom-right" />
  <Component :is="layout" />
</template>

<script lang="ts" setup>
import PublicLayout from '@/common/layouts/PublicLayout.vue'
import { provide, shallowRef } from 'vue'
import router from '@/router'
import { Layout, layouts } from '@/common/layouts'
import Toast from 'primevue/toast'
import TheEnvBanner from './common/components/layout/TheEnvBanner.vue'

const layout = shallowRef<unknown>(PublicLayout)
router.afterEach((to) => {
  layout.value = layouts[to.meta.layout || Layout.Public]
})
provide('app:layout', layout)
</script>
