export default {
  activityKpi: {
    title: 'KPI Métiers',
    dataTableHeader: 'Valeurs du ',
    columnTitle: {
      partnerSollicitations: 'Sollicitations Partenaires',
      usersAndClientIndicators: 'Indicateurs utilisateurs / clients',
      AFFILIATED_PROJECT: 'Affiliations Partenaires',
      value: 'Valeur'
    },
    type: {
      TOTAL_USERS: 'Nombre de Comptes utilisateur',
      TOTAL_CLIENTS: 'Nombre de Clients',
      TOTAL_ACTIVES_CLIENTS: 'Nombre de Clients Actifs',
      TOTAL_QUALIFIEDS_PROSPECTS: 'Nombre de Prospects Qualifiés',
      USERS_WITH_PROJECT: 'Nombre d’Utilisateurs avec au moins un projet créé',
      USERS_WITH_SCENARIO: 'Nombre d’Utilisateurs avec au moins un scénario de travaux créé',
      USERS_WITH_BUDGET: 'Nombre d’Utilisateurs avec au moins un budget prévisionnel validé',
      USERS_REQUESTING_FINANCO: 'Nombre d’Utilisateurs ayant sollicité un contact Financo',
      USERS_REQUESTING_ARTISAN: 'Nombre de demandes de devis à des professionnels partenaires',
      USERS_REQUESTING_DIAGNOSTICIAN:
        'Nombre de demandes de devis à des diagnostiqueurs partenaires',
      TOTAL_REQUEST_CONTACT_TO_ACCOMPANIST_AGENCY:
        'Nombre de demandes de contact à des accompagnateurs partenaires',
      TOTAL_ARTISAN_COMPANY: 'Nombre d’Entreprises Professionnels',
      TOTAL_ACCOMPANIST_COMPANY: 'Nombre d’Entreprises Accompagnateur',
      TOTAL_ACTIVE_ACCOMPANISTS: 'Nombre d’Accompagnateurs actifs',
      COMPLETED_PROJECTS: 'Nombre de projet réalisés',
      SIGNED_QUOTATIONS: 'Nombre de devis signés',
      CO2_SAVING: 'Economie CO2 cumulée',
      TOTAL_QUOTATIONS_REQUESTS: 'Nombre de devis demandés par Professionnel',
      QUOTATION_CONVERSION_RATE: 'Taux de conversion devis par Entreprise Professionnel',
      WELCOME_PACK_DAY_ONE: 'Mail welcome pack J+1',
      WELCOME_PACK_DAY_FOUR: 'Mail welcome pack J+4',
      AFFILIATED_PROJECT: 'Nombre de projets affiliés',
      TOTAL_PROJECTS: 'Nombre de projets total'
    },
    period: {
      title: 'Période des KPI:',
      label: 'Sélectionner une période'
    },
    scale: {
      title: 'Abscisse du graphique:',
      perDay: 'Par jour',
      per_week: 'Par semaine',
      per_month: 'Par mois',
      per_year: 'Par an'
    },
    noCurvesToDisplay: 'Aucune courbe à afficher',
    addCurve: 'Ajouter la courbe',
    removeCurve: 'Retirer la courbe',
    legend: {
      title: 'Légende',
      user: {
        title: 'Utilisateur :',
        description:
          '{title} visiteur qui s’est identifié de manière unique sur notre site, en se connectant avec un nom d’utilisateur et un mot de passe.'
      },
      client: {
        title: 'Client :',
        description:
          '{title} utilisateur ayant créé un compte (CGU acceptées) et qui a au moins sollicité des devis et/ou ayant sollicité un contact Financo.'
      },
      activeClient: {
        title: 'Client actif :',
        description: '{title} client qui se connecte au moins une fois par mois.'
      }
    },
    excludedMails: {
      title: 'Mails exclus'
    },
    display: {
      title: 'Affichage',
      description:
        'Lorsqu’un KPI n’est pas incrémenté sur la période sélectionnée en haut de page, il ne sera pas affiché. Sélectionnez une période large pour visualiser tous les KPI.'
    }
  },
  activityKpiMarketing: {
    title: 'KPI Marketing'
  }
}
