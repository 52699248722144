<script setup lang="ts">
import { ref } from 'vue'

const env = ref(import.meta.env.VITE_APP_ENV)

const bannerColor = ref('#1742cf')
switch (env.value) {
  case 'DEV':
    bannerColor.value = '#1742cf'
    break
  case 'INT':
    bannerColor.value = '#76ab1e'
    break
  case 'REC':
    bannerColor.value = '#c00808'
    break
  case 'PPROD':
    bannerColor.value = '#d2f146'
    break
  default:
    break
}
</script>
<template>
  <div v-if="env !== 'PROD'" class="banner-container">
    <div class="banner">{{ env }}</div>
  </div>
</template>
<style>
.banner-container {
  pointer-events: none;
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  &:after {
    content: '';
    display: block;
    width: 10px;
    height: 5px;
    background-color: darken(v-bind(bannerColor), 5%);
    position: absolute;
    top: 0;
    left: 0;
  }
  &:before {
    content: '';
    display: block;
    height: 10px;
    width: 5px;
    background-color: darken(v-bind(bannerColor), 5%);
    position: absolute;
    bottom: 0;
    right: 0;
  }
  z-index: 999;
}

.banner {
  width: 200px;
  padding: 15px;
  background-color: v-bind(bannerColor);
  color: #fff;
  text-align: center;
  transform: rotate(45deg) translate(10%, 10%);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}
</style>
