export default {
  partnerMap: {
    title: 'Carte des partenaires Nidomio',
    displayWhiteZone: 'Afficher les zones couvertes',
    person: {
      tag: 'Type de personne (agence)',
      type: {
        accompanistAgency: 'Accompagnateurs',
        artisanAgency: 'Professionnels',
        diagnosticianAgency: 'Diagnostiqueurs'
      }
    },
    reset: "Revenir à l'état initial"
  }
}
