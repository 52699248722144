export default {
  diagnostician: {
    title: 'Diagnostiqueurs',
    titleCompanies: 'Entreprises Diagnostiqueurs',
    titleAgencies: 'Agences Diagnostiqueurs',
    table: {
      company: {
        title: 'Liste des entreprises diagnostiqueurs'
      },
      agency: {
        title: 'Liste des agences diagnostiqueurs'
      },
      columns: {
        diagnosticianCompany: 'Entreprise Diagnostiqueur'
      }
    },
    creation: {
      company: {
        title: "Création d'une entreprise diagnostiqueur"
      },
      agency: {
        title: "Création d'une agence diagnostiqueur"
      }
    },
    update: {
      company: {
        title: "Détails de l'entreprise diagnostiqueur"
      },
      agency: {
        title: "Détails de l'agence diagnostiqueur"
      }
    },
    errors: {
      create: {
        CONFLICT: 'Une personne existe déjà avec ces informations'
      },
      update: {
        CONFLICT: 'Une personne existe déjà avec ces informations'
      },
      account: {
        create:
          'Une erreur est survenu lors de la création du compte utilisateur pour le diagnostiqueur'
      }
    },
    company: {
      delete: {
        title: 'Supprimer définitivement une entreprise diagnostiqueur'
      }
    },
    agency: {
      delete: {
        title: 'Supprimer définitivement une agence diagnostiqueur'
      }
    },
    contracts: {
      table: {
        title: 'Liste des contrats',
        columns: {
          commissionDpe: 'Commission DPE',
          commissionAudit: 'Commission Audit',
          retrocommisionRate: 'Retrocommission',
          signedDate: 'Date de signature',
          terminationDate: 'Date de rupture',
          reasonForTermination: 'Motif de rupture'
        }
      }
    }
  }
}
