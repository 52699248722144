export default {
  artisan: {
    otherTitle: 'Professionnels',
    titleArtisansEmployees: 'Salariés Professionnels',
    titleArtisansCompanies: 'Entreprises Professionnelles',
    titleArtisansAgencies: 'Agences Professionnelles',
    companyState: "État de l'entreprise :",
    agencyState: "État de l'agence :",
    artisanCompanyState: {
      DIRECTORY: 'Annuaire',
      PARTNER: 'Partenaire'
    },
    artisanAgencyState: {
      PARTNER: 'Partenaire',
      DIRECTORY: 'Annuaire RGE'
    },
    artisanCompanyStateLabel: 'État de la société Professionnelle',
    artisanAgencyStateLabel: "État de l'agence Professionnelle",
    table: {
      title: 'Liste des salariés professionnels',
      company: {
        title: 'Liste des entreprises professionnelles'
      },
      agency: {
        title: 'Liste des agences professionnelles'
      },
      columns: {
        lastname: 'Nom',
        firstname: 'Prénom',
        company: 'Entreprise',
        phoneNumber: 'Numéro de téléphone',
        artisanCompany: 'Entreprise'
      }
    },
    professionals: {
      quotationState: {
        SUBMITTED: 'Devis déposé',
        SEND: 'Devis envoyé',
        RELAUNCHED: 'Demande de devis en attente',
        DELETED: 'Devis supprimé'
      },
      quotationRequestState: {
        SEND: 'Demande de devis envoyé',
        RELAUNCHED: 'Demande de devis en attente',
        DELETED: 'Demande de devis supprimé'
      }
    },
    contracts: {
      table: {
        title: 'Liste des contrats',
        columns: {
          commisionRate: 'Commission',
          retrocommisionRate: 'Retrocommission',
          signedDate: 'Date de signature',
          terminationDate: 'Date de rupture',
          reasonForTermination: 'Motif de rupture'
        }
      }
    },
    creation: {
      company: {
        title: "Création d'une entreprise professionnelle"
      },
      agency: {
        title: "Création d'une agence professionnelle"
      },
      title: "Création d'un salarié professionnel"
    },
    update: {
      company: {
        title: "Détails de l'entreprise professionnelle"
      },
      agency: {
        title: "Détails de l'agence professionnelle"
      },
      title: 'Détail du salarié professionnel'
    },
    delete: {
      salary: {
        title: 'Supprimer définitivement un professionnel du bâtiment'
      }
    },
    company: {
      delete: {
        title: 'Supprimer définitivement une entreprise professionelle'
      }
    },
    agency: {
      delete: {
        title: 'Supprimer définitivement une agence professionelle'
      }
    },
    errors: {
      create: {
        CONFLICT: 'Une personne existe déjà avec ces informations'
      },
      update: {
        CONFLICT: 'Une personne existe déjà avec ces informations'
      },
      account: {
        create:
          'Une erreur est survenu lors de la création du compte utilisateur pour un professionnel'
      }
    }
  }
}
