<template>
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1232_7009)">
      <path
        d="M36.0593 13.3324C36.3296 13.9567 36.0427 14.6818 35.4185 14.9521C35.3639 14.9757 35.3095 14.9994 35.2553 15.023C39.3741 16.441 42.758 18.1993 45.4672 20.485C46.3171 21.202 47.0961 21.9672 47.8079 22.7844C47.8023 22.6992 47.7966 22.6138 47.7907 22.5282C47.7441 21.8495 48.2565 21.2616 48.9352 21.215C49.6138 21.1685 50.2017 21.6809 50.2483 22.3595C50.3476 23.8068 50.4068 25.2066 50.4272 26.5618C51.2542 28.0801 51.9296 29.7391 52.4668 31.5536C52.6599 32.2058 52.2877 32.8911 51.6355 33.0843C51.0963 33.2439 50.5344 33.0172 50.2468 32.5653C50.0097 35.5664 49.5526 38.3274 48.896 40.8925C49.2688 40.6389 49.6501 40.3766 50.0406 40.105C50.5991 39.7167 51.3667 39.8546 51.755 40.4131C52.1434 40.9715 52.0055 41.7391 51.447 42.1275C50.1395 43.0367 48.9246 43.8508 47.769 44.5844C47.4838 45.383 47.1773 46.1621 46.8502 46.9232C46.5817 47.5482 45.8573 47.8372 45.2323 47.5686C44.6073 47.3001 44.3184 46.5757 44.5869 45.9507C46.773 40.8631 48.0166 34.8379 47.9707 27.2206C46.8793 25.3547 45.5258 23.7574 43.8787 22.3678C40.9732 19.9165 37.0889 18.0563 31.9555 16.5617C28.3072 18.3967 25.4426 20.3613 22.7292 22.9844C22.3737 23.328 21.8473 23.4258 21.3922 23.2327C20.937 23.0396 20.6415 22.5932 20.6415 22.0988C20.6415 20.6743 20.8654 19.5273 21.2118 17.9435C21.3572 17.2789 22.0138 16.8581 22.6783 17.0034C23.3428 17.1488 23.7637 17.8053 23.6183 18.4699C23.5766 18.6608 23.5373 18.8422 23.5007 19.0155C26.6137 16.5038 30.054 14.5905 34.4396 12.6916C35.0639 12.4213 35.789 12.7082 36.0593 13.3324Z"
        fill="white"
      />
      <path
        d="M44.0869 38.0831C44.1533 37.4061 43.6583 36.8035 42.9813 36.7371C42.3043 36.6707 41.7017 37.1658 41.6353 37.8427C41.3591 40.6605 40.8025 42.9469 39.6695 45.0454C38.2215 43.3842 38.5227 42.0803 38.6055 41.8143C38.9498 40.707 39.2722 39.6489 39.6473 38.0353C40.459 34.5441 40.0679 28.5211 32.693 26.2442C30.6965 25.6406 30.0013 26.332 30.5422 27.353C30.5991 27.4604 30.6696 27.5813 30.7479 27.7158C31.4138 28.8586 32.6504 30.9806 31.0126 34.1646C29.0215 38.0353 29.6378 42.8232 35.5789 44.7288C36.5878 45.0523 36.9082 44.7831 36.1955 44.1123C34.1112 42.1506 32.217 38.6818 33.0009 35.3129C33.4183 33.5194 35.1309 33.9678 34.9065 35.6492C34.9002 35.6967 34.8936 35.7454 34.8868 35.7952C34.6542 37.5078 34.2368 40.5809 38.1859 45.4397C38.4898 45.7887 38.7445 46.0443 38.9585 46.2246C38.8894 46.3281 38.8185 46.4313 38.7461 46.5342C36.5192 47.416 34.1688 48.1032 31.3093 48.7639C24.8747 46.6113 20.8968 43.9422 17.1807 38.4093C16.8014 37.8446 16.0362 37.6943 15.4715 38.0736C14.9068 38.4529 14.7565 39.2181 15.1358 39.7828C19.2635 45.9286 23.7954 48.8855 30.7999 51.1902C30.8891 51.2196 30.9793 51.2382 31.0691 51.2468C31.2261 51.2746 31.3912 51.2721 31.5565 51.2345C33.1434 50.8733 34.5951 50.502 35.9668 50.0932C35.9429 50.4289 36.0558 50.773 36.3073 51.0348C36.7787 51.5252 37.5584 51.5407 38.0488 51.0694C42.0918 47.1838 43.5712 43.3438 44.0869 38.0831Z"
        fill="white"
      />
      <path
        d="M13.0856 31.2635C13.919 29.8921 14.7643 28.6067 15.6334 27.3979C15.4119 24.9154 15.5029 22.2853 15.8734 19.5676C15.9653 18.8936 16.5861 18.4217 17.2602 18.5135C17.9342 18.6054 18.4061 19.2263 18.3142 19.9003C17.1621 28.3514 18.9298 35.2762 23.9399 39.4287C24.4636 39.8628 24.5363 40.6393 24.1022 41.163C23.6681 41.6868 22.8916 41.7594 22.3679 41.3254C19.1441 38.6533 17.1473 35.0816 16.1915 30.9487C15.8563 31.4649 15.523 31.9961 15.1907 32.5429C14.8374 33.1242 14.0798 33.3091 13.4984 32.9558C12.9171 32.6025 12.7323 31.8449 13.0856 31.2635Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1232_7009"
        x1="32.712"
        y1="12.5898"
        x2="32.712"
        y2="51.413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0A9BA5" />
        <stop offset="1" stop-color="#72B74A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1232_7009"
        x1="32.712"
        y1="12.5898"
        x2="32.712"
        y2="51.413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0A9BA5" />
        <stop offset="1" stop-color="#72B74A" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1232_7009"
        x1="32.712"
        y1="12.5898"
        x2="32.712"
        y2="51.413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0A9BA5" />
        <stop offset="1" stop-color="#72B74A" />
      </linearGradient>
    </defs>
  </svg>
</template>
