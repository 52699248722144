export default {
  scenario: {
    label: 'Scénario',
    whatIsScenario: "Qu'est ce qu'un scénario ?",
    title: {
      create: 'Créer un scénario',
      update: 'Modifier un scénario'
    },
    back: 'Retourner au projet',
    register: 'Enregistrer le scénario',
    steps: {
      resources: 'Profil fiscal',
      workType: 'Poste de travaux',
      worksAmount: 'Montant des travaux',
      aidsLabel: 'Aides'
    },
    learnMoreScenario: "En savoir plus sur les scénarios @:{'common.siteName'}",
    estimateAmountWork: 'Montant estimé des travaux : ',
    estimateAmountFunding: 'Montant estimé des aides : ',
    estimateleftAmount: 'Reste à charge : ',
    createScenarioDescriptionAccompanist:
      'Qu’est-ce que c’est un scénario, ses étapes, son rôle et ses avantages.',
    createScenarioDescriptionClient: {
      create:
        'Vous avez précisé votre projet de rénovation. Vous allez maintenant créer un scénario de travaux.',
      definition:
        'Un scénario de travaux définit un bouquet de travaux à réaliser, estime le coût de ces travaux et le montant des aides auxquelles vous pouvez prétendre.',
      goodInformation:
        "Bon à savoir : @:{'common.siteName'} est pour le moment destiné aux projets suivis par un accompagnateur. Cet accompagnateur vous aide à définir le scénario à réaliser en fonction de votre situation."
    },
    resourceCeiling: {
      anahScale:
        "Selon le barème de ressources de l'ANAH, votre foyer se situe dans le profil de couleur {color}",
      anahDetail:
        "L'Agence Nationale pour l'Amélioration de l'Habitat (ANAH) vous octroie, selon votre profil fiscal, des aides à la rénovation pour améliorer le confort de votre logement. Découvrez votre barème (violet, jaune, rose ou bleu) pour mieux évaluer le montant de vos primes",
      label: 'Profil fiscal',
      validate: 'Définir les aides',
      validateClient: 'Définir les travaux',
      formLabel: 'Sélectionnez le profil fiscal du propriétaire * :',
      formLabelAnah: "Sélectionner votre profil fiscal tel que défini par l'ANAH * :",
      formUpdateLabel: 'Modifier le profil fiscal',
      colors: {
        BLUE: 'bleu (très modestes).',
        YELLOW: 'jaune (modestes).',
        PURPLE: 'violet (intermédiaires).',
        PINK: 'rose (hauts revenus).'
      },
      discoverAids: 'découvrez vos aides',
      colorsExplanation: {
        BLUE: "Profil bleu : @:{'scenario.resourceCeiling.discoverAids'}",
        YELLOW: "Profil jaune : @:{'scenario.resourceCeiling.discoverAids'}",
        PURPLE: "Profil violet : @:{'scenario.resourceCeiling.discoverAids'}",
        PINK: "Profil rose : @:{'scenario.resourceCeiling.discoverAids'}"
      },
      labels: {
        BLUE: 'Très modestes',
        YELLOW: 'Modestes',
        PURPLE: 'Intermédiaires',
        PINK: 'Hauts revenus'
      },
      error: 'Merci de sélectionner une catégorie de ressource.',
      resourceCategoryIdentification: {
        client: {
          title: 'J’ai besoin d’aide pour identifier mon profil fiscal',
          residenceLabel: 'Votre lieu de résidence fiscale se situe en Ile-de-France : ',
          personFiscalResidenceLabel: 'Combien de personnes composent votre foyer fiscal ? ',
          findTaxIncomeLabel: 'Où trouver mon revenu fiscal de référence ?'
        },
        accompanist: {
          title: 'J’ai besoin d’aide pour identifier le profil fiscal',
          residenceLabel:
            'Le lieu de résidence fiscale du propriétaire se situe en Ile-de-France : ',
          personFiscalResidenceLabel:
            'Combien de personnes composent le foyer fiscal du propriétaire ? '
        },
        differentTaxRegimesLabel:
          'Découvrez le montant de vos aides selon votre régime fiscal de référence'
      },
      tax: {
        between: '{start} à {end}',
        moreThan: 'Supérieur à {end}'
      }
    },
    funding: {
      label: 'Budget prévisionnel du projet',
      detail:
        'Avant de solliciter des professionnels pour obtenir des devis, nous vous proposons de définir un budget prévisionnel, sur la base des éléments fournis précédemment.',
      evaluateCapacity: 'Définir mon budget prévisionnel'
    },
    situation: {
      paragraph: {
        first:
          "@:{'common.siteName'} est pour le moment destiné aux projets suivis par un accompagnateur",
        second:
          'Un accompagnateur est un acteur neutre qui vous aidera à faire un diagnostic de votre situation, à identifier les travaux à réaliser et les aides auxquelles vous pouvez prétendre.'
      },
      list: {
        couldBe: "Il peut s'agir :",
        ofOne: "d'un",
        consultingSpace: "Espace Conseil France Renov'",
        accompanist: "Mon Accompagnateur Rénov'"
      },
      message: "En savoir plus sur les solutions d'accompagnement mises à ma disposition.",
      choice: {
        title: 'Quelle est votre situation ?',
        alreadyHaveAccompanist: "J'ai déjà un accompagnateur",
        findAccompanist: 'Je souhaite trouver un accompagnateur',
        withoutAccompanist: 'Je souhaite continuer sans accompagnateur'
      }
    },
    worksSelect: {
      label: 'Sélectionnez les différents postes de travaux souhaités pour le scénario : ',
      action: 'Définir le montant estimé des travaux',
      error: 'Merci de sélectionner au moins un poste de travaux.'
    },
    aids: {
      helpLabel: 'Aides',
      primeRenov: 'MaPrimeRénov’',
      primeSerenite: 'MaPrimeRénov’ Sérénité',
      othersAidAmount: 'Autres (aides locales, prime coup de pouce...)',
      primeCEE: 'Certificats d’Économies d’Énergies (CEE)',
      title: 'Renseignez le montant total estimé des aides :',
      totalAids: 'Montant total',
      aidsDetail: 'Détails des aides',
      publicAids: 'Aides publiques',
      privateAids: 'Aides privées',
      errors: {
        totalAids: 'Merci de renseigner un montant.'
      }
    },
    workCategory: {
      HEATING: 'Chauffage et eau chaude',
      ENERGY_PRODUCTION: "Production d'énergie",
      WALL_ISOLATION: 'Isolation des murs',
      FLOOR_ISOLATION: 'Isolation des planchers bas',
      VENTILATION: 'Ventilation',
      DOOR_ISOLATION: 'Isolation des parois vitrées et portes',
      ROOF_ISOLATION: 'Isolation de la toiture'
    },
    amountsWorks: {
      form: {
        totalAmount: 'Montant total',
        error: {
          required: 'Merci de renseigner un montant.'
        },
        button: {
          labels: {
            defineResource: 'Définir les ressources',
            defineAids: 'Définir les aides'
          }
        }
      },
      description: 'Renseignez le montant total estimé des travaux :',
      workAssistanceMessage:
        'Des difficultés à estimer le montant de vos travaux ? Rien de plus normal 😉 : faites-vous aider par un accompagnateur neutre qui vous aidera à y voir plus clair dans votre projet.',
      accordion: {
        title: 'Détails par poste de travaux',
        thermalIsolation: 'Isolation, menuiserie et ventilation',
        heating: 'Chauffage et eau chaude',
        energyProduction: "Production d'énergie",
        accommodationAdaptation: 'Adaptation du logement',
        updateWorks: 'Modifier les travaux'
      }
    },
    summary: {
      workEstimate: 'Estimation travaux :',
      aidEstimate: 'Estimation aides :',
      leftAmount: 'Reste à charge :',
      informations: {
        stateAid:
          "Attention : il vous faudra avancer les aides de l'Etat. Pensez bien à le prendre en compte dans votre plan de financement !",
        learnMore: "En savoir plus sur l'avance des aides.",
        first: 'Pensez à informer votre client qu’il faudra avancer les aides de l’État.',
        second:
          'Retrouvez ici les solutions de financement à proposer à votre client pour le reste à charge.'
      },

      amountToFinance: 'Montant à financer :',
      amountToAdvance: 'Montant à avancer :'
    },
    dialogs: {
      amountUpdate: {
        wishUpdate: 'Oui, mettre à jour',
        cancel: 'Non, conserver',
        amountWorksUpdate: {
          header: 'Mise à jour du montant des travaux',
          errorAmount:
            'Le détail par poste de travaux est supérieur au montant total estimé des travaux.',
          wishUpdateAmount: 'Souhaitez-vous mettre à jour le montant total estimé des travaux ?'
        },
        amountAidsUpdate: {
          header: 'Mise à jour du montant des aides',
          errorAmount: 'Le détail des aides est supérieur au montant total estimé des aides.',
          wishUpdateAmount: 'Souhaitez-vous mettre à jour le montant total estimé des aides ?'
        }
      }
    },
    notifications: {
      saveSuccess: 'Le scénario a bien été enregistré'
    },
    scenarios: 'Scénarios',
    quotationRequest: {
      manage: {
        title: 'Gérer les professionnels sollicités',
        noResponseReceived:
          "Vous n'avez pas reçu de réponse ? Vous pourrez les relancer via {siteName} sous 21 jours.",
        quoteSent:
          'Vos demandes de devis ont bien été envoyées à ces professionnels et seront traitées dans les meilleurs délais.'
      },
      button: 'Solliciter d’autres professionnels',
      declineArtisan: {
        label: 'Annuler ma demande de devis',
        areYouSure:
          'Êtes-vous sûr de vouloir annuler votre demande de devis au près de {artianCompany} ? ',
        willBeInformed:
          'Le professionnel sera informé de votre souhait de ne plus être contacté pour ce projet.',
        reasonsDescription:
          "Dans le cadre de l'amélioration de nos services, pourriez-vous nous indiquer la raison de votre annulation ?",
        placeholder: 'Sélectionner un motif',
        options: {
          NO_RESPONSE_RECEIVED: 'Je n’ai pas reçu de réponse',
          MORE_TIME_TO_THINK: "J'ai besoin de plus de temps pour réfléchir ",
          FIND_OTHER_ARTISAN: 'J’ai trouvé un autre professionnel',
          PROJECT_CANCELED: 'Mon projet est annulé',
          MIND_CHANGED: "J'ai changé d'avis / Ça me m'intéresse plus",
          DONT_WANT_SAY: 'Je ne souhaite pas me prononcer'
        },
        buttons: {
          accept: 'Oui, annuler ma demande de devis',
          reject: 'Non, revenir à mes sollicitations'
        },
        error: 'Merci de sélectionner un motif de suppression.',
        successNotification: 'La demande à {artisanCompany} a été annulée.'
      },
      requested: 'Sollicité le {date}',
      relaunched: 'Relancé le {date}',
      rejected: 'Annulé le {date}'
    }
  }
}
