export default {
  budget: {
    exampleTitle: "Exemple d'un budget prévisionnel",
    title: 'Mon budget prévisionnel',
    creationDate: 'Date de création du budget: {date}',
    tooltip: {
      success: 'Votre budget prévisionnel est enregistré.',
      //TODO: à mettre dans la page contactFinanco en query apres la validation du budget
      contact:
        'Votre demande de contact est enregistrée. Vous allez recevoir un courriel récapitulatif avec votre numéro de dossier.'
    },
    personalContribution: {
      explanation:
        'Avant de solliciter des professionnels pour obtenir des devis, nous vous proposons de définir un budget ' +
        'prévisionnel, sur la base des éléments fournis précédemment.',
      utilityDescription:
        'Ce budget prévisionnel vous permettra d’identifier comment vous comptez :',
      utility1: 'payer les acomptes à la signature des devis',
      utility2: 'payer les factures dès leur réception',
      understandTheBudgetMessage: "Comprendre le budget d'un projet de rénovation",
      contributionField: {
        label: 'De quel apport disposez-vous déjà pour financer votre projet ?',
        errors: {
          required: "Merci de saisir votre apport (saisir 0 si vous n'avez pas d'apport).",
          minMax: 'Merci de saisir un montant inférieur ou égal au coût estimé des travaux.'
        }
      },
      submitButton: 'Valider mon apport'
    },
    advanceAnahLabel: "Avance de l'ANAH",
    depositAnahLabel: "Acomptes de l'ANAH",
    creditFundLabel: 'Fonds à crédit',
    budgetAllocationLabel: 'Comment souhaitez-vous répartir votre budget ?',
    complementarySolutionsLabel: 'Les solutions complémentaires pour financer votre projet :',
    avertissementCredit:
      'Un crédit vous engage et doit être remboursé. Vérifiez vos capacités de remboursement avant de vous engager.',
    errors: {
      creditFundMinMax: 'Le montant à emprunter doit être compris entre {min} et {max}',
      advanceAnahMax: "L'avance de l'ANAH ne doit pas dépasser {max}",
      budgetBalanceMaxError:
        'La répartition de votre budget ne peut pas excéder le coût estimé des travaux. Merci de modifier votre saisie. ',
      budgetBalanceMinError:
        'La répartition de votre budget ne couvre pas la totalité du coût estimé des travaux. Merci de modifier votre saisie. '
    },
    button: {
      defineCreditFund: 'Définir les fonds à crédit',
      validateBudget: 'Valider mon budget prévisionnel'
    },
    graphic: {
      title: 'Coût estimé des travaux :',
      creditFund: 'Fonds à obtenir à crédit ',
      advance: 'Avance :',
      contribution: 'Apport',
      workAmount: 'Fonds à trouver pour financer le projet',
      error:
        'La répartition de votre budget ne peut pas excéder le coût estimé des travaux. Merci de modifier votre saisie.'
    },
    understand: {
      explanationLabel: 'Comprendre le budget d’un projet de rénovation',
      cost: 'coût',
      accompanist: 'accompagnateur',
      renovationCostExplanation:
        'Un projet de rénovation à un {cost} correspondant aux travaux à réaliser tels que définis par votre scénario. Ce coût a été estimé avec l’aide de votre {accompanist} ou sur la base des premiers devis obtenus.',
      advisorRecommendation:
        "Si vous n'avez pas d'accompagnateur, nous vous conseillons d'en trouver un pour revoir votre projet, il est neutre et pourra vous guider.",
      aids: 'Les aides',
      renovationAssistanceExplanation:
        'Votre accompagnateur a identifié avec vous les aides auxquelles vous pouvez prétendre pour vos travaux.',
      paymentExplanationFirst:
        'Les aides ne vous seront versées qu’une fois les travaux complètement réalisés (sur présentation des factures).',
      paymentExplanationSecond:
        'Notamment dans un projet faisant intervenir plusieurs professionnels, vous pourrez être amené à devoir payer un premier professionnel plusieurs mois avant la fin complète du chantier.',
      paymentExplanationThird:
        'Les professionnels n’aiment pas avoir à relancer pour être payés et pour cette raison, sont réticents à intervenir en premier dans un projet de rénovation globale qui fait intervenir plusieurs corps de métier. Lui garantir que vous avez les fonds pour le payer dès réception de la facture l’encourage donc à répondre à votre sollicitation de devis.',
      anahAdvanceExplanation:
        "L'Agence Nationale pour l’Amélioration de l'habitat (ANAH) peut sous certaines conditions, vous verser une avance et des acomptes sur les aides Ma Prime Rénov’ ou Ma Prime Rénov’ Sérénité. Nous vous conseillons de vous rapprocher de votre Accompagnateur pour vérifier ce à quoi vous avez le droit.",
      anahAdvanceTitle: 'L’avance de l’ANAH',
      anahInstallmentsTitle: 'Les acomptes de l’ANAH',
      anahAdvanceConditionsFirst:
        'L’avance de l’ANAH doit vous permettre de payer les acomptes demandés par les professionnels à la signature des devis. Elle doit donc être demandée avant le démarrage des travaux et dans les 6 mois suivant l’accord de l’ANAH.',
      anahAdvanceConditionsSecondBold:
        'Cette avance est réservée aux ménages au profil fiscal TRES MODESTE',
      anahAdvanceConditionsSecond: '{modest} et pour les aides {renov} et {serenity}',
      renov: 'Ma Prime Rénov’',
      serenity: 'Ma Prime Rénov’ Sérénité',
      serenityUnique: 'Ma Prime Rénov’ Sérénité uniquement',
      anahAdvanceConditionsThird:
        'Elle ne peut excéder 70% de votre aide Ma Prime Rénov ou 50% de votre aide Ma Prime Rénov Sérénité.',

      anahAdvanceRequestFirst:
        'Vous pouvez demander des acomptes à l’ANAH dès que vous avez réalisé au moins 25% des travaux.',
      anahAdvanceRequestSecondBold:
        'Les acomptes sont réservés aux ménages au profil fiscal MODESTE ou TRES MODESTE',
      anahAdvanceRequestSecond: '{modest} et pour {serenity}',
      anahAdvanceRequestThird:
        'Ils ne peuvent pas excéder 70% des aides prévues (avec déduction des avances éventuelles).',

      renovationProjectBudgetTitle: 'Le budget de votre projet de rénovation',

      deposit: 'payer les acomptes à la signature des devis',
      bill: 'payer les factures dès leur réception',
      budgetNotes: 'Votre budget doit vous permettre d’identifier comment : ',

      apport: 'apport',
      anahAids: 'avances sur les aides',
      creditsTerm: 'crédits',
      contribution:
        'De votre {apport}, issu de votre épargne ou d’un crédit que vous avez déjà souscrit',
      advances: 'Des {anahAids} que vous souhaitez demander à l’ANAH',
      credits: 'Des {creditsTerm} : pour le reste, vous aurez à emprunter les fonds qu’il manque',
      budgetComposition: 'Votre budget est constitué :',

      budgetExampleTitle: 'Exemple :',
      budgetExampleFirst:
        'Pour un coût de travaux estimé à 30 000€, vous pouvez obtenir 12 000 € d’aides.',
      budgetExampleSecondWithAnah:
        'Si vous avez 9 000 € d’apport et que vous souhaitez demander un acompte de 6 000 € à l’ANAH, il vous faudra prévoir un crédit de 15 000 € pour la réalisation de votre projet.',
      budgetExampleSecondWithoutAnah:
        'Si vous avez 9 000 € d’apport, il vous faudra prévoir un crédit de 21 000 € pour la réalisation de votre projet.',
      creditsTitle: 'Les crédits',
      ecoPTZLoan: 'Eco-Prêt à taux zéro (aussi appelé Eco-PTZ)',
      personalLoan: 'Prêt Personnel Rénovation Financo',
      ecoPTZAdvantage:
        'Si vous avez à emprunter de l’argent pour finaliser votre budget, l’Eco-PTZ est évidemment intéressant car il est à taux zéro !',
      ecoPTZIntro:
        'Mais il a aussi quelques restrictions et inconvénients qu’il est important que vous connaissiez :',
      ecoPTZPoint1:
        'Il ne peut être accordé que pour la rénovation de la résidence principale (ou principale locative)',
      ecoPTZPoint2:
        'Il ne peut financer que les travaux d’économies d’énergie. A titre d’exemple, si des travaux de rénovation sont faits conjointement à des travaux d‘extension, pour les travaux qui concernent tout le logement (comme le système de chauffage), l’Eco-PTZ ne financera ces travaux qu’au prorata de la surface de la partie ancienne.',
      ecoPTZPoint3:
        'Les travaux éligibles sont les mêmes que ceux donnant droit aux aides, les travaux induits de terrassement, de peinture, d’électricité… ne peuvent pas être financés par un Eco-PTZ',
      ecoPTZPoint4:
        'Toutes les banques ne proposent pas l’éco-PTZ. La banque que vous solliciterez risque de vous demander de changer de banque.',
      financoIntro:
        'Vous l’aurez compris, l’Eco-PTZ ne convient pas à toutes les situations et ne couvre pas tous les travaux. Vous pourriez avoir besoin d’un autre prêt en complément ou en remplacement de l’Eco-PTZ.',
      financoDetail:
        'Financo propose propose un crédit flexible dédié aux projets de rénovation : le Prêt Personnel Rénovation.',
      financoPoint1:
        'Vous pourrez débloquer progressivement les fonds nécessaires au fur et à mesure de vos besoins (paiement des acomptes, des factures){note1}.',
      financoPoint2:
        'Vous ne remboursez le capital emprunté qu’une fois vos travaux réalisés{note2}. Tant que tous les déblocages n’ont pas été effectués, vous remboursez uniquement les intérêts et l’assurance facultative* si vous y avez adhéré{note1}. ',
      financoPoint2_1:
        'Tant que tous les déblocages n’ont pas été effectués, vous remboursez uniquement les intérêts et l’assurance facultative* si vous y avez adhéré{note1}.',
      financoPoint3: 'Une fois que vous avez reçu vos aides que se passe-t-il ? ',
      financoPoint3_1:
        'À réception de vos aides ou subventions, vous pouvez demander dans les 24 mois suivant la signature de votre contrat de crédit à faire un remboursement anticipé d’une partie du capital, sans frais. Ainsi vous diminuerez le montant de vos mensualités{note3}.',
      financoPoint4:
        'Et si finalement vous n’utilisez pas l’intégralité du capital prévu au contrat ? ',
      financoPoint4_1:
        'Si vous n’utilisez pas la totalité du montant de votre prêt personnel dans le délai imparti de 12 mois, la somme restante ne sera simplement pas débloquée et n’aura donc pas besoin d’être remboursée.Si vous ne débloquez aucun fond, vous ne remboursez rien.',
      financoConclusion:
        'Nous utiliserons le Prêt Personnel Rénovation Financo pour vous permettre de tester votre capacité de remboursement.',

      studyBudget: 'Étudier mon budget projet',
      texteContrat: {
        paragraphOne:
          '(1) Sous réserve d’acceptation de la demande par Financo, conformément à ses règles de gestion en vigueur à la date de la demande. Le Prêt Personnel Rénovation permet le déblocage du montant du prêt personnel en 6 fois maximum. Les déblocages seront possibles durant une période de 12 mois à compter de la date du premier déblocage.',
        paragraphTwo:
          '(2) En cas de non achèvement des travaux, le remboursement du capital emprunté débutera automatiquement 12 mois après le premier déblocage.',
        paragraphThree:
          '(3) Passé les 24 premiers mois, et conformément à la réglementation en vigueur, le prêteur pourra exiger une indemnité dont le montant sera fonction du montant de remboursement anticipé et de la date à laquelle ce dernier intervient. En cas de remboursement partiel, vous avez la possibilité',
        paragraphFour:
          '*Contrat d’assurance facultative «Mon Assurance de personnes» n°5035 est un contrat d’assurance de groupe des emprunteurs souscrit auprès des sociétés Suravenir et Suravenir Assurances, entreprises régies par le Code des assurances.'
      }
    },
    infoCreditFundRemaining:
      'Afin de pouvoir payer les acomptes à la signature des devis (environ 30% du devis) et payer les factures des travaux dès réception, vous devez définir comment vous allez financer les { creditFundRemaining }.',
    remainings: 'restants',
    infoAids:
      'Les {aids} d’aides, que vous obtiendrez après les travaux, pourront servir à rembourser une partie de vos emprunts par anticipation et ainsi diminuer vos mensualités',
    backWording: {
      budgetPersonalContribution: 'Retourner aux fonds disponibles',
      returnBudgetExplanation: "Retourner à 'Comprendre le budget'"
    },
    completelyFinancingByClient:
      'Félicitations ! Vous avez les fonds nécessaires pour financer votre projet !',
    goodToKnow: 'Bon à savoir : ',
    goodToKnowMention:
      'vous pouvez avoir recours des solutions complémentaires si vous souhaitez conserver votre épargne. Vous pouvez également valider votre budget prévisionnel tel quel. ',
    popin: {
      advance: {
        title: 'Avance de l’ANAH',
        paragraph: {
          first:
            'Votre profil fiscal vous permet de demander une avance à l’ANAH. Cette avance doit être demandée avant le démarrage des travaux et dans les 6 mois suivant l’accord de l’ANAH.',
          second:
            'Cette avance ne peut excéder 50% de votre aide Ma Prime Rénov Sérénité ou 70% de votre aide Ma Prime Rénov.',
          third:
            'Vous n’avez pas défini exactement à quelles aides vous pouvez prétendre. Nous limitons donc cette avance à 50% du total des aides que vous avez spécifiées. N’hésitez à modifier votre scénario si vous souhaitez que nous soyons plus précis.'
        }
      },
      deposit: {
        title: 'Acomptes de l’ANAH',
        paragraph: {
          first:
            'Votre profil fiscal vous permet de demander une avance à l’ANAH dès que vous avez réalisé au moins 25% des travaux.',
          second:
            "Les acomptes ne peuvent excéder 70% de l'aide Ma Prime Rénov' Sérénité (déduction faite des avances éventuellement demandées)."
        }
      },
      ecoPtz: {
        title: 'Eco-Prêt à Taux Zéro',
        paragraph: {
          first:
            'Si vous avez à emprunter de l’argent pour finaliser votre budget, l’Eco-PTZ est évidemment intéressant car il est à taux zéro !',
          second:
            'Mais il a aussi quelques restrictions et inconvénients qu’il est important que vous connaissiez :',
          list: {
            first:
              'Il ne peut être accordé que pour la rénovation de la résidence principale (ou principale locative)',
            second:
              'Il ne peut financer que les travaux d’économies d’énergie. À titre d’exemple, si des travaux de rénovation sont faits conjointement à des travaux d‘extension, pour les travaux qui concernent tout le logement (comme le système de chauffage), l’Eco-PTZ ne financera ces travaux qu’au prorata de la surface de la partie ancienne.',
            third:
              'Les travaux éligibles sont les mêmes que ceux donnant droit aux aides, les travaux induits de terrassement, de peinture, d’électricité… ne peuvent pas être financés par un Eco-PTZ',
            fourth:
              'Toutes les banques ne proposent pas l’éco-PTZ. La banque que vous solliciterez risque de vous demander de changer de banque.'
          }
        }
      },
      personalRenovation: {
        title: 'Prêt Personnel Rénovation',
        paragraph: {
          first:
            'L’Eco-PTZ ne convient pas à toutes les situations et ne couvre pas tous les travaux. Vous pourriez avoir besoin d’un autre prêt en complément ou en remplacement de l’Eco-PTZ.',
          second:
            'Financo propose propose un crédit flexible dédié aux projets de rénovation : le Prêt Personnel Rénovation.',
          list: {
            first:
              'Vous pourrez débloquer progressivement les fonds nécessaires au fur et à mesure de vos besoins (paiement des acomptes, des factures).',
            second: {
              question: 'Vous ne remboursez le capital emprunté qu’une fois vos travaux réalisés.',
              answer:
                'Tant que tous les déblocages n’ont pas été effectués, vous remboursez uniquement les intérêts et l’assurance facultative* si vous y avez adhéré.'
            },
            third: {
              question: 'Une fois que vous avez reçu vos aides que se passe-t-il ?',
              answer:
                'À réception de vos aides ou subventions, vous pouvez demander dans les 24 mois suivant la signature de votre contrat de crédit à faire un remboursement anticipé d’une partie du capital, sans frais. Ainsi vous diminuerez le montant de vos mensualités.'
            },
            fourth: {
              question:
                'Et si finalement vous n’utilisez pas l’intégralité du capital prévu au contrat ?',
              answer:
                'Si vous n’utilisez pas la totalité du montant de votre prêt personnel dans le délai imparti de 12 mois, la somme restante ne sera simplement pas débloquée et n’aura donc pas besoin d’être remboursée. Si vous ne débloquez aucun fond, vous ne remboursez rien.'
            }
          }
        },
        footer: {
          first:
            '(1) Sous réserve d’acceptation de la demande par Financo, conformément à ses règles de gestion en vigueur à la date de la demande. Le Prêt Personnel Rénovation permet le déblocage du montant du prêt personnel en 6 fois maximum. Les déblocages seront possibles durant une période de 12 mois à compter de la date du premier déblocage.',
          second:
            '(2) En cas de non achèvement des travaux, le remboursement du capital emprunté débutera automatiquement 12 mois après le premier déblocage.',
          third:
            '(3) Passé les 24 premiers mois, et conformément à la réglementation en vigueur, le prêteur pourra exiger une indemnité dont le montant sera fonction du montant de remboursement anticipé et de la date à laquelle ce dernier intervient. En cas de remboursement partiel, vous avez la possibilité',
          optionalInsuranceContract:
            '*Contrat d’assurance facultative «Mon Assurance de personnes» n°5035 est un contrat d’assurance de groupe des emprunteurs souscrit auprès des sociétés Suravenir et Suravenir Assurances, entreprises régies par le Code des assurances.'
        }
      }
    }
  }
}
