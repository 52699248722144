import { createApp } from 'vue'
import { createPinia } from 'pinia'
import VueDOMPurifyHTML from 'vue-dompurify-html'

import App from '@/App.vue'
import router from '@/router'

import interceptor from '@/common/api/interceptor'
// PrimeVue
import PrimeVue from 'primevue/config'
import primeVueFr from '@/plugins/primevue/fr.json'
//theme
import '@/common/assets/theme.css'
import '@/common/assets/main.scss'
// I18N
import { i18n } from '@/plugins/i18n'
import vFocus from './common/directives/focus'

import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'

async function prepare() {
  // if (import.meta.env.VITE_APP_ENV === 'DEV') {
  //   const { worker } = await import('./mocks/browser') // Mock Server
  //   return worker.start({ onUnhandledRequest: 'bypass' })
  // }
  return Promise.resolve()
}

prepare().then(() => {
  const app = createApp(App)
    .use(VueDOMPurifyHTML)
    .use(createPinia())
    .use(router)
    .use(PrimeVue, { locale: primeVueFr })
    .use(i18n)
    .use(ToastService)
    .use(ConfirmationService)
    .use(interceptor)
    .directive('focus', vFocus)

  app.mount('#app')
})
