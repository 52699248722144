export default {
  signin: {
    title: 'Connexion',
    form: {
      inputEmailPlaceholder: 'Adresse email',
      inputEmailLabel: 'Adresse email',
      inputPasswordLabel: 'Mot de passe',
      inputPasswordPlaceholder: 'Mot de passe',
      submitButtonLabel: 'Me connecter',
      identifyButtonLabel: "M'identifier",
      error: {
        emailRequired: 'Merci de saisir une adresse email pour vous identifier.',
        passwordRequired: 'Merci de saisir un mot de passe pour vous identifier.',
        validatePasswordFormat: 'Le format de votre mot de passe ne respecte pas la règle',
        validateEmail: 'Merci de saisir une adresse email valide pour vous identifier.'
      },
      info: {
        password: {
          details: 'Le mot de passe doit contenir 8 caractères dont: ',
          upper: '1 majuscule',
          lower: '1 minuscule',
          digit: '1 chiffre',
          special: '1 caractère spécial'
        }
      }
    }
  }
}
