export default [
  {
    path: 'projects',
    children: [
      {
        path: '',
        name: 'projects',
        component: () => import('@/project/views/ProjectList.vue')
      },
      {
        path: ':projectId',
        children: [
          {
            path: '',
            name: 'projectSummary',
            meta: {
              backWording: 'client.projectSummary.backWording.detail',
              backName: 'projects'
            },
            component: () => import('@/project/views/ProjectSummary.vue')
          }
        ]
      }
    ]
  }
]
